import { useMountedEffect } from "@/common/hooks/use-mounted-effect";
import { LoginForm } from "@/features/authentication/components/login-form";
import {
  AuthenticationValidationError,
  authenticator,
  commitSession,
  getUserSession,
} from "@/features/authentication/session.server";
import { PublicLayout } from "@/features/navigation/components/public-layout.component";
import { AFTER_LOGIN_PAGE } from "@/libs/platform/navigation";
import { Anchor, Container, Group, Image, Paper, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import type { ActionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Link,
  useActionData,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import { IconCheck } from "@tabler/icons-react";
import { AuthorizationError } from "remix-auth";

export default function Login() {
  const navigation = useNavigation();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const message = searchParameters.get("message");
  const actionData = useActionData<typeof action>();

  const errorFromSearchParameters = searchParameters.get("error");

  useMountedEffect(() => {
    if (message) {
      showNotification({
        message,
        color: "teal",
        icon: <IconCheck size={16} />,
      });
      setSearchParameters({});
    }
  }, []);

  return (
    <PublicLayout>
      <Container size="xs" p="md">
        <Paper
          withBorder
          py="xl"
          px={{
            base: "md",
            md: 80,
          }}
        >
          <Image
            src="/images/DT_logo_circle.svg"
            alt="Designers Trust logo"
            width={64}
            sx={{ margin: "0 auto" }}
          />
          <Text weight={600} align="center" mt="md">
            Welcome to Designers Trust
          </Text>
          <Text align="center" mb="xl">
            Please log in to your account
          </Text>
          <LoginForm
            errors={
              actionData && "inputErrors" in actionData
                ? actionData.inputErrors
                : undefined
            }
            formError={
              actionData && "error" in actionData
                ? actionData.error
                : errorFromSearchParameters
                  ? [errorFromSearchParameters]
                  : undefined
            }
            isLoading={navigation.state === "submitting"}
          />
          <Group position="apart" mt="xl">
            <Anchor component={Link} to="/password-reset">
              <Text size="sm">Forgot password?</Text>
            </Anchor>
            <Anchor component={Link} to="/register">
              <Text size="sm">Don&apos;t have an account?</Text>
            </Anchor>
          </Group>
        </Paper>
      </Container>
    </PublicLayout>
  );
}

export async function action({ request }: ActionArgs) {
  try {
    const user = await authenticator.authenticate("user-pass", request, {
      throwOnError: true,
    });

    const session = await getUserSession(request);
    session.set(authenticator.sessionKey, user);

    const headers = new Headers({ "Set-Cookie": await commitSession(session) });

    return redirect(AFTER_LOGIN_PAGE[user.activeRole], { headers });
  } catch (error) {
    if (error instanceof Response)
      return json({
        error: await error.json(),
      });

    if (error instanceof AuthorizationError) {
      if (error.cause instanceof AuthenticationValidationError) {
        return json({
          success: false as const,
          inputErrors: error.cause.errors,
        });
      }

      return json({
        success: false as const,
        error: error.message,
      });
    }

    return json({
      error: "Unknown error",
    });
  }
}

import { Alert, Button, PasswordInput, TextInput } from "@mantine/core";
import { Form } from "@remix-run/react";
import { IconAlertCircle } from "@tabler/icons-react";

type Properties = {
  isLoading?: boolean;
  errors?: {
    email?: string[];
    password?: string[];
  };
  formError?: string[];
};

export const LoginForm = ({ isLoading, errors, formError }: Properties) => {
  return (
    <Form method="post">
      <TextInput
        name="email"
        label="Email address"
        placeholder="Email address here"
        error={errors?.email?.[0]}
      />
      <PasswordInput
        name="password"
        label="Password"
        placeholder="Password"
        description="If you have asked for quote, please enter the password you want to use for your account"
        error={errors?.password?.[0]}
        mt="xl"
      />
      {formError && (
        <Alert color="red" mt="xl" icon={<IconAlertCircle size={16} />}>
          {formError}
        </Alert>
      )}
      <Button fullWidth loading={isLoading} type="submit" color="green" mt={36}>
        Log in
      </Button>
    </Form>
  );
};
